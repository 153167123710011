import {Link} from 'react-router-dom';
import {ReactNode} from 'react';
import {AuthLayoutFooter} from './auth-layout-footer';
import {useIsDarkMode} from '@ui/themes/use-is-dark-mode';
import authBgSvg from './auth-bg.svg';
import {useTrans} from '@ui/i18n/use-trans';
import {useSettings} from '@ui/settings/use-settings';

interface AuthPageProps {
  heading?: ReactNode;
  message?: ReactNode;
  children: ReactNode;
}
export function AuthLayout({heading, children, message}: AuthPageProps) {
  const {branding} = useSettings();
  const isDarkMode = useIsDarkMode();
  const {trans} = useTrans();

  return (
    <main
      className="flex items-center justify-center py-30 min-h-[100vh] overflow-y-auto bg-alt px-14 dark:bg-none md:px-10vw"
    >
      <Link
        to="/"
        className="mb-40 block flex-shrink-0"
        aria-label={trans({message: 'Go to homepage'})}
      >
        {/* <img
          src={isDarkMode ? branding.logo_light : branding?.logo_dark}
          className="m-auto block h-42 w-auto"
          alt=""
        /> */}
      </Link>
      <div className="mx-auto w-full max-w-440 rounded-lg bg px-40 pb-32 pt-40 shadow md:shadow-xl">
        {heading && <h1 className="mb-20 text-xl">{heading}</h1>}
        {children}
      {message && <div className="mt-36 text-sm text-[#9F9F9F]">{message}</div>}
      </div>
      {/* <AuthLayoutFooter /> */}
    </main>
  );
}
