import { cloneElement, ReactElement, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

interface DashboardContentProps {
  children: ReactElement<{ className: string }>;
  isScrollable?: boolean;
  stableScrollbar?: boolean;
}
export function DashboardContent({
  children,
  isScrollable = true,
  stableScrollbar = true,
}: DashboardContentProps) {
  let path = useLocation();
  let [adminPage, setAdminPage] = useState(false);

  useEffect(() => {
    if (path.pathname.indexOf('admin') !== -1) {
      setAdminPage(true);
    } else {
      setAdminPage(false);
    }
  }, [path.pathname]);

  return cloneElement(children, {
    className: clsx(
      children.props.className,
      isScrollable && 'overflow-y-auto',
      isScrollable && stableScrollbar && 'stable-scrollbar',
      'dashboard-grid-content',
      'has-[.dashboard-stable-scrollbar]:stable-scrollbar',
      adminPage && 'lg:ms-[-50px] lg:z-[-99999]',
    ),
  });
}
