import PlusIcon from '@app/drive/layout/icons/PlusIcon'
import React, { useEffect, useState } from 'react'
import { searchUsers } from './API';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentChatReciever } from '@app/lib/slices/chatSlice';
import { RootState } from '@app/lib/store';

interface AddChatModalProbs{
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AddChatModal({open, setOpen} : AddChatModalProbs) {

    let [modalOpen, setModalOpen] = useState(open);
    let [itemClicked, setItemClicked] = useState(false);
    let [searchQuery, setSearchQuery] = useState('');
    const [users, setUsers] = useState<User[] | null>(null);

    // useEffect(()=>{
    //     const handleKeyboard = (e: KeyboardEvent)=>{         
    //         if (e.key === 'Enter')
    //             handleSearch();
    //     }

    //     document.addEventListener('keydown', handleKeyboard);
    //     return(()=>{
    //         document.removeEventListener('keydown', handleKeyboard);
    //     })
    // }, [])
    

    useEffect(() => {
        setUsers(null);
    }, [searchQuery]);

    // console.log(users);

    function handleSearch(e: React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        if (searchQuery !== '') {
            searchUsers({ input: searchQuery })
                .then((response) => {
                    const userData = response.data.records as User[];
                    setUsers(userData);
                })
                .catch((error) => {
                    console.log('Error fetching users:', error);
                    setUsers(null);
                });
        }
        else{
            setUsers(null);
        }
    }
    
    
    useEffect(()=>{
        setModalOpen(open);
    }, [open])

    
    useEffect(()=>{
        setModalOpen(false);
    }, [itemClicked])

  return (
    <div className={` top-0 left-0  right-0 bottom-0 bg-black bg-opacity-40 flex items-center justify-center ${modalOpen ? 'fixed': 'hidden'}`}>
        <div className='w-full max-w-[450px] bg-white flex flex-col dark:bg rounded p-20 dark:border max-h-[90vh]'>
            <div className='flex justify-between items-center'>
                <h3 className='text-black dark:text-white font-[500] text-xl'>Add Chat</h3>
                <button className='text-black dark:text-white' onClick={()=>{setModalOpen(false); setOpen(false)}}><i className='fa-solid fa-x'></i></button>
            </div>
            <hr />
            <div className='mt-16'>
                <form onSubmit={handleSearch} noValidate>
                    <label htmlFor="chatEmail" className='text-black dark:text-muted'>Reciever email/name</label>
                    <div className='relative '>
                        <input value={searchQuery} onChange={(e)=>{setSearchQuery(e.target.value)}} type="email" id='chatEmail' name='chat_email' className='bg-[#EAEBF0] dark:bg dark:border dark:text-white  py-12 pl-20 pr-32 rounded-[8px] w-full' required/>
                        <button className='absolute top-1/2 translate-y-[-50%] right-0 w-32 text-center' type='submit'><i className="fa-solid fa-magnifying-glass text-xl text-center w-full text-black dark:text-white"></i></button>
                    </div>
                    
                </form>
            </div>

            <div className='overflow-y-scroll flex-grow mt-20 flex flex-col gap-16 px-10'>
                {users?.map(user=><UserSearchItem key={user.id} user={user} setModalOpen={setModalOpen}/>)}
            </div>
        </div>
    </div>
  )
}

interface UserSearchItemProps {
    user: User;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


function UserSearchItem({user, setModalOpen}: UserSearchItemProps){
    let dispatch = useDispatch();

    function handleClick(){
        dispatch(setCurrentChatReciever(user));
        setModalOpen(false);
    }

  return (
    <div
        className='w-full flex items-center gap-20 cursor-pointer hover:bg-alt p-10 rounded-lg overflow-hidden'
        onClick={handleClick}
        >
        <div className='w-50 h-50 rounded-lg overflow-hidden'>
            <img src={user.image? user.image: (user.avatar? user.avatar: undefined)} className='' alt="" />
        </div>
        <p className='text-black dark:text-white'>{user.name}</p>
    </div>
  );
}