import React, { useState } from 'react';
import ImageCircle from './ImageCircle';
import { useAuth } from '@common/auth/use-auth';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/lib/store';
import { FileTypeIcon } from '@common/uploads/components/file-type-icon/file-type-icon';
import { downloadFileByUrl, getFileCategory } from './functions';
import { downloadFile } from './API';
import axios, { AxiosResponse } from 'axios';

interface FileInfo {
    new_name: string;
    old_name: string;
  }

interface MessageProps {
    content?: string;
    time: Date | string | undefined;
    side: 0 | 1;
    userImage: string;
    file: FileInfo | undefined;
}

interface downloadResponse{
    download_path: string;
    file_name: string;
}

// Function to format time
function formatTime(date: Date | string): string {
    const d = typeof date === 'string' ? new Date(date) : date;
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedHours = hours % 12 || 12; // Converts 0 to 12 for midnight
    const formattedMinutes = String(minutes).padStart(2, '0'); // Pads minutes with a leading zero

    return `${!isToday(d) ? getDayName(d) : ''} ${formattedHours}:${formattedMinutes} ${ampm}`;
}

function isToday(createdAt: Date): boolean {
    const today = new Date();

    return createdAt.getDate() === today.getDate() &&
            createdAt.getMonth() === today.getMonth() &&
            createdAt.getFullYear() === today.getFullYear();
};

function getDayName(date: Date): string{
    return date.toLocaleDateString('en-US', { weekday: 'long' });
};

export default function Message({ content, time, side, userImage, file }: MessageProps) {
    let {user: sender} = useAuth();
    let { currentReciever } = useSelector((state: RootState) => state.chat);
    let recieverImg = currentReciever?.image? currentReciever?.image: (currentReciever?.avatar? currentReciever?.avatar: '');
    let senderImg = sender?.image? sender?.image: '';
    let image = recieverImg;
    let [downloading, setDownloading] = useState(false);
    const truncateText = (text: string, maxChars: number) => {
        return text.length > maxChars ? text.slice(0, maxChars) + '...' : text;
      };
    
    // if (file)
    //     console.log(file.old_name);

    function handleDownload(){
        if (file){
            setDownloading(true);
            axios.get<downloadResponse>(`/chat/api/download/${file?.new_name}`).then(data=>{
                console.log(data.data);
                downloadFileByUrl(data.data.download_path, data.data.file_name).then(data=>{
                    setDownloading(false);
                })
            })

        }
    }
    
    
    
    if (side===0)
        image=senderImg;
    
    if (content==='' && file){
        return (
            <div className={`flex gap-8 ${side === 0 ? 'justify-end' : 'justify-start'}`}>
                {side === 1 ? <ImageCircle image={image}></ImageCircle> : <></>}
                <div>
                    <div className={`${side === 0 ? 'bg-primary text-white' : 'bg-white dark:bg dark:text-white text-black'} max-w-[300px] sm:max-w-[350px] 2xl:max-w-[436px] font-[500] text-[12px] md:text-[14px] leading-[21px] py-12 px-16 rounded-[16px]`}>
                        <div className='bg-white bg-opacity-30 backdrop-blur-lg p-4 rounded-lg shadow-lg px-8 py-10'>
                            <div className='flex items-center'>
                                <FileTypeIcon type={getFileCategory(file['old_name']) || undefined} className={`${side === 0 ? 'fill-white' : 'fill-primary dark:fill-white'}`}/>
                                <p className='ml-28 w-[70%] overflow-hidden break-words'>{file.old_name}</p>
                            </div>
                            <button onClick={handleDownload} className='bg-primary text-white  px-4  shadow hover:bg-opacity-70 transition duration-150 ease-in-out
 w-full mt-10 py-6 rounded'>{downloading ? <i className={`fa-solid fa-circle-notch  fa-spin text-white`}></i> : 'Download'}</button>
                        </div>
                    </div>
                    <p className={`${side === 0 ? 'text-right' : 'text-left'} font-[500] text-[12px] text-[#888888] leading-[18px] mt-8`}>
                        {time ? formatTime(time) : 'N/A'}
                    </p>
                </div>
                {side === 0 ? <ImageCircle image={image}></ImageCircle> : <></>}
            </div>
        );
    }
    else{
        return (
            <div className={`flex gap-8 ${side === 0 ? 'justify-end' : 'justify-start'}`}>
                {side === 1 ? <ImageCircle image={image}></ImageCircle> : <></>}
                <div>
                    <div className={`${side === 0 ? 'bg-primary text-white' : 'bg-white dark:bg dark:text-white text-black'} max-w-[300px] sm:max-w-[436px] font-[500] text-[12px] md:text-[14px] leading-[21px] py-12 px-16 rounded-[16px]`}>
                        <p>{content}</p>
                    </div>
                    <p className={`${side === 0 ? 'text-right' : 'text-left'} font-[500] text-[12px] text-[#888888] leading-[18px] mt-8`}>
                        {time ? formatTime(time) : 'N/A'}
                    </p>
                </div>
                {side === 0 ? <ImageCircle image={image}></ImageCircle> : <></>}
            </div>
        );
    }
}
