import React from 'react'

interface ImageCircleProps {
    image: string;
}

export default function ImageCircle({image}: ImageCircleProps) {
  return (
    <div className='w-40 h-40 rounded-full overflow-hidden'>
      <img src={image} alt="user image" loading='lazy'/>
    </div>
  )
}
