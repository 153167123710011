import React, { useEffect, useRef, useState } from 'react';
import ImageCircle from './ImageCircle';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/lib/store';
import ChatInput from './MessageInput';
import Message from './Message';
import { fetchMessages } from './API';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@common/auth/use-auth';
import Pusher from 'pusher-js';

export default function ChatContainer() {
  const dispatch = useDispatch();
  const { currentReciever } = useSelector((state: RootState) => state.chat);
  const { user: sender } = useAuth();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'instant' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Fetch initial messages using react-query
  const { data, isLoading, error } = useQuery({
    queryKey: ['getMessages', currentReciever?.id],
    queryFn: () => fetchMessages({ id: currentReciever?.id || 0 }),
    refetchInterval: 200, // Adjust as needed
    enabled: !!currentReciever?.id, // Only run if currentReciever is set
  });

  useEffect(() => {
    if (data?.data?.messages) {
      setMessages(data.data.messages);
    }
  }, [data]);

  useEffect(() => {
    if (!currentReciever) return;

    // Initialize Pusher
    const pusher = new Pusher('6ed01a2426b65664ae9f', {
      cluster: 'eu',
    });

    const channel = pusher.subscribe(`private-chatify.${currentReciever.id}`);

    // Bind to an event
    channel.bind('messaging', (data: ChatMessage) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    // Clean up when the component unmounts or currentReciever changes
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [currentReciever]); // Added currentReciever to dependency array

  if (!currentReciever) return null;

  return (
    <div className='flex-grow flex flex-col'>
      <ContainerHeader 
        userImage={currentReciever.image || currentReciever.avatar || ''} 
        userName={currentReciever.name || `${currentReciever.first_name} ${currentReciever.last_name}`}
      />
      <div className='pb-20 flex-grow overflow-y-scroll'>
        {messages.map((message: ChatMessage) => {
          // console.log(message.attachment);
          
          return (
            <Message 
            file = {message.attachment ? JSON.parse(message.attachment) : undefined}
            key={message.id}
            content={message.body}
            side={sender?.id === message.from_id ? 0 : 1}
            time={message.created_at ? new Date(message.created_at) : undefined}
            userImage=''
          />
          );
        })}
        <div ref={chatEndRef}/>
      </div>
      <ChatInput />
    </div>
  );
}

interface ContainerHeaderProps {
  userImage: string;
  userName: string;
}

function ContainerHeader({ userImage, userName }: ContainerHeaderProps) {
  return (
    <div className='w-full rounded-ld bg-white dark:bg p-16 flex justify-between items-center gap-16 rounded-xl mb-24'>
      <div className='flex items-center gap-8'>
        <ImageCircle image={userImage} />
        <span className='text-darkBlue dark:text-white text-[14px] md:text-[18px] font-[500]'>{userName}</span>
      </div>

    </div>
  );
}

function Dots() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.625 12C8.625 12.0995 8.58549 12.1948 8.51517 12.2652C8.44484 12.3355 8.34946 12.375 8.25 12.375C8.15054 12.375 8.05516 12.3355 7.98484 12.2652C7.91451 12.1948 7.875 12.0995 7.875 12C7.875 11.9005 7.91451 11.8052 7.98484 11.7348C8.05516 11.6645 8.15054 11.625 8.25 11.625C8.34946 11.625 8.44484 11.6645 8.51517 11.7348C8.58549 11.8052 8.625 11.9005 8.625 12ZM8.625 12H8.25M12.375 12C12.375 12.0995 12.3355 12.1948 12.2652 12.2652C12.1948 12.3355 12.0995 12.375 12 12.375C11.9005 12.375 11.8052 12.3355 11.7348 12.2652C11.6645 12.1948 11.625 12.0995 11.625 12C11.625 11.9005 11.6645 11.8052 11.7348 11.7348C11.8052 11.6645 11.9005 11.625 12 11.625C12.0995 11.625 12.1948 11.6645 12.2652 11.7348C12.3355 11.8052 12.375 11.9005 12.375 12ZM12.375 12H12M16.125 12C16.125 12.0995 16.0855 12.1948 16.0152 12.2652C15.9448 12.3355 15.8495 12.375 15.75 12.375C15.6505 12.375 15.5552 12.3355 15.4848 12.2652C15.4145 12.1948 15.375 12.0995 15.375 12C15.375 11.9005 15.4145 11.8052 15.4848 11.7348C15.5552 11.6645 15.6505 11.625 15.75 11.625C15.8495 11.625 15.9448 11.6645 16.0152 11.7348C16.0855 11.8052 16.125 11.9005 16.125 12ZM16.125 12H15.75M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#0154A0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
