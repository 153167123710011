import { createSlice } from "@reduxjs/toolkit";

export const rightSideNavSlice = createSlice({
    name: 'rightSideNav',
    initialState: {
        chart: false,
        details: false
    },
    reducers: {
        openChart: (state) => { state.chart = true },
        openDetails: (state) => { state.details = true },
        closeDetails: (state) => { state.details = false },
        closeChart: (state) => { state.chart = false },
        setChart: (state, action) => { state.chart = action.payload },
        setDetails: (state, action) => { state.details = action.payload },
        toggleChart: (state) => {state.chart = !state.chart},
        toggleDetails: (state) => {state.details = !state.details},
    }
})

export const { openChart, openDetails, closeDetails, closeChart, setChart, setDetails, toggleChart, toggleDetails } = rightSideNavSlice.actions;

export default rightSideNavSlice.reducer;
