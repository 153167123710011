import { createSlice } from "@reduxjs/toolkit";

interface ChatState {
    currentReciever: User | null;
}

const initialState: ChatState = {
    currentReciever: null,
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers:{
        setCurrentChatReciever: (state, action) => {state.currentReciever = action.payload}
    }
})

export default chatSlice.reducer;
export const {setCurrentChatReciever} = chatSlice.actions;