import React, { useState } from 'react'
import InboxItem from './InboxItem';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/lib/store';
import { useQuery } from '@tanstack/react-query';
import { getContacts } from './API';

interface InpoxProbs{
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Inbox({setModalOpen}:InpoxProbs) {

  let { currentReciever } = useSelector((state: RootState) => state.chat);
  let {data} = useQuery({
    queryKey: ['getContacts'],
    queryFn: ()=>getContacts(),
    refetchInterval: 200
  })

  const contacts = data?.data?.contacts
  // console.log(contacts);
  
  // getContacts().then(data=>console.log(data));
  

  return (
    <div className='h-full min-w-[312px] max-w-[336px] w-full rounded-[16px] p-16 bg-white dark:bg'>
      <InboxHeader setModalOpen={setModalOpen}></InboxHeader>
      <div className='mt-24 flex flex-col gap-16'>
        {contacts?.map((contact: User)=>{
          
        return (<InboxItem 
          avatar={contact?.image || contact?.avatar || ''}
          name={`${contact.first_name} ${contact.last_name}`}
          time='12:00 PM'
          displayedMessage='Hi, I am Ahmed Esmail. How is everything!'
          id={1}
          user={contact}
          ></InboxItem>);
        })}
        
      </div>
    </div>
  )
}


function InboxHeader({setModalOpen}:InpoxProbs){
    return (
        <div className='w-full flex items-center justify-between'>
            <h3 className='text-darkBlue text-[18px] font-[500] leading-[27px] dark:text-white'>Inbox</h3>
            <button className='bg-primary text-white rounded-[10px]'  onClick={(e)=>{setModalOpen(true); e.preventDefault();}}>
              <div className='py-10 px-16 flex items-center gap-8'>
                <PlusIcon></PlusIcon>
                <span className='text-[14px] leading-[21px] font-[500]'>Add chat</span>
              </div>
            </button>
        </div>
    );
}

function PlusIcon(){
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4.5V19.5M19.5 12H4.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
    );
}