import axios from "axios";

// 1. Chat Authentication
interface ChatAuthBody {
    channel_name: string;
    socket_id: string;
}

export function chatAuthentication(body: ChatAuthBody) {
    return axios.post('/chat/api/chat/auth', body);
}

// 2. Get User or Group Info
interface GetUserInfoBody {
    id: number;
    type: 'user' | 'group';
}

export function getChatInfo(body: GetUserInfoBody) {
    return axios.get('/chat/api/idInfo', { params: body });
}

// 3. Send Message
interface SendMessageBody {
    id: number; // Recipient ID
    message: string; // Message text
    type: string; // Message type
    file?: File; // Optional file
}

export function sendMessage(body: SendMessageBody) {
    const formData = new FormData();
    formData.append("id", body.id.toString());
    formData.append("message", body.message);
    formData.append("type", body.type);
    if (body.file) {
        formData.append("file", body.file);
    }
    return axios.post('/chat/api/sendMessage', formData);
    // , {
    //     headers: { "Content-Type": "multipart/form-data" }
    // }
}

// 4. Fetch Messages
interface FetchMessagesParams {
    id: number; // User or group ID
    per_page?: number; // Optional number of messages per page
}

export function fetchMessages(params: FetchMessagesParams) {
    return axios.get('/chat/api/fetchMessages', { params });
}

// 5. Download File
export function downloadFile(fileName: string) {
    return axios.get(`/chat/api/download/${fileName}`, { responseType: 'blob' });
}

// 6. Mark Message as Seen
interface MarkSeenBody {
    id: number; // Message ID
}

export function markMessageAsSeen(body: MarkSeenBody) {
    return axios.post('/chat/api/makeSeen', body);
}

// 7. Get Contacts
interface GetContactsParams {
    per_page?: number; // Optional number of contacts per page
}

export function getContacts(params?: GetContactsParams) {
    return axios.get('/chat/api/getContacts', { params });
}

// 8. Add to Favorites
interface AddToFavoritesBody {
    user_id: number; // User ID to add to favorites
}

export function addToFavorites(body: AddToFavoritesBody) {
    return axios.post('/chat/api/star', body);
}

// 9. Get Favorites
export function getFavorites() {
    return axios.get('/chat/api/favorites');
}

// 10. Search Users
interface SearchUsersParams {
    input: string; // Search query
}

export function searchUsers(params: SearchUsersParams) {
    return axios.get('/chat/api/search', { params });
}

// 11. Get Shared Images
interface GetSharedImagesParams {
    user_id: number; // User ID
}

export function getSharedImages(params: GetSharedImagesParams) {
    return axios.get('/chat/api/shared', { params });
}

// 12. Delete Conversation
interface DeleteConversationBody {
    id: number; // Conversation ID
}

export function deleteConversation({id}: DeleteConversationBody) {
    return axios.delete(`/chat/api/deleteConversation/${id}`);
}

// 13. Update Settings
interface UpdateSettingsBody {
    dark_mode: 'dark' | 'light'; // Theme setting
}

export function updateSettings(body: UpdateSettingsBody) {
    return axios.post('/chat/api/updateSettings', body);
}

// 14. Set Active Status
interface SetActiveStatusBody {
    status: boolean; // Active status
}

export function setActiveStatus(body: SetActiveStatusBody) {
    return axios.post('/chat/api/setActiveStatus', body);
}
