export async function downloadFileByUrl(url: string, filename: string) {
    try {
        const response = await fetch(url);
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        
        document.body.appendChild(a);
        a.click();
        
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
    } catch (error) {
        console.error('Download failed:', error);
    }
}

export function getFileCategory(fileName: string): string | null {
    if (!fileName){
        return '';
    }
    const extension = fileName.split('.').pop()?.toLowerCase();

    const fileCategories: { [key: string]: string } = {
        // Image types
        jpg: 'image',
        jpeg: 'image',
        png: 'image',
        gif: 'image',
        bmp: 'image',
        svg: 'image',

        // Video types
        mp4: 'video',
        mkv: 'video',
        avi: 'video',

        // Audio types
        mp3: 'audio',
        wav: 'audio',
        ogg: 'audio',
    };

    return fileCategories[extension || ''] || 'file';
}