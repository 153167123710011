import {Link, useSearchParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormTextField} from '@ui/forms/input-field/text-field/text-field';
import {Button} from '@ui/buttons/button';
import {Form} from '@ui/forms/form';
import {LinkStyle} from '@ui/buttons/external-link';
import {AuthLayout} from './auth-layout/auth-layout';
import {
  SendPasswordResetEmailPayload,
  useSendPasswordResetEmail,
} from '../requests/send-reset-password-email';
import {Trans} from '@ui/i18n/trans';
import {StaticPageTitle} from '../../seo/static-page-title';
import {useSettings} from '@ui/settings/use-settings';
import companyLogo from '../../../../../../public/images/company-logo.png';

export function ForgotPasswordPage() {
  const {registration} = useSettings();

  const [searchParams] = useSearchParams();
  const searchParamsEmail = searchParams.get('email') || undefined;

  const form = useForm<SendPasswordResetEmailPayload>({
    defaultValues: {email: searchParamsEmail},
  });
  const sendEmail = useSendPasswordResetEmail(form);

  const message = !registration?.disable && (
    <Trans
      values={{
        a: parts => (
          <Link className={'text-main'} to="/register">
            {parts}
          </Link>
        ),
      }}
      message="Don't have an account? <a>Sign up.</a>"
    />
  );

  return (
    <AuthLayout message={message}>
      <StaticPageTitle>
        <Trans message="Forgot Password" />
      </StaticPageTitle>
      <Form
        form={form}
        onSubmit={payload => {
          sendEmail.mutate(payload);
        }}
      >
        <div className="mb-32">
    <img src={companyLogo} className='w-[129px] block mx-auto' alt="" />
    <div className='text-center text-2xl text-[#0154a0] dark:text-white text-[32px] font-[600] poppins'>
      <Trans message="Reset Password" />
    </div>
    <h2 className='font-[500] text-sm text-center text-[#888] mt-10 poppins'>Enter your email address below and we will send you a link to reset or create your password.</h2>
        </div>
        <FormTextField
          disabled={!!searchParamsEmail}
          className="mb-32"
          name="email"
          type="email"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          label={<span className='text-black dark:text-muted text-[14px] poppins font-[500]'><Trans message="Email" /></span>}
          required
        />
        <Button
          className="block w-full bg-main hover:bg-main text-white"
          type="submit"
          variant="flat"
          size="md"
          disabled={sendEmail.isPending}
        >
          <Trans message="Continue" />
        </Button>
      </Form>
    </AuthLayout>
  );
}
