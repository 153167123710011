import React from 'react'

interface plansCardProbs{
    price: string;
    storage: string;
    period: string;
    badge?: string;
    description: string;
}

export default function PlansCard({price, storage, period, badge, description}: plansCardProbs) {

    console.log(badge);
    
    
  return (
    <div className='plans-card-container'>
        <div className='plans-card'>
            <div className='plans-card-content'>
                {badge?<div className="plans-card-badge">{badge}</div>:''}
                <div>
                    <h3>{storage}</h3>
                    <p className='plans-card-description'>{description}</p>
                </div>
                <div className='flex items-center gap-8'>
                    <span>${price}</span>
                    <p className='plans-card-period'>Per <br/> {period}</p>
                </div>
            </div>
            <button>Get started</button>
        </div>
    </div>
  )
}
