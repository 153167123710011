import React, { useEffect, useState } from 'react';
import {StorageMeter} from './storage-summary/storage-meter';
import {WorkspaceSelector} from '@common/workspace/workspace-selector';
import {RootFolderPage} from '../../drive-page/drive-page';
import {SidebarMenu} from './sidebar-menu';
import {useNavigate} from '@common/ui/navigation/use-navigate';
import {CreateNewButton} from '../create-new-button';
import {Button} from '@ui/buttons/button';
import {Link, useLocation} from 'react-router-dom';
import {Trans} from '@ui/i18n/trans';
import {useAuth} from '@common/auth/use-auth';
import clsx from 'clsx';
import {useSettings} from '@ui/settings/use-settings';
import companyLogo from '../../../../../public/images/company-logo.png';
import Inbox from '@app/chat_components/Inbox';
import AddChatModal from '@app/chat_components/AddChatModal';

interface SidebarProps {
  className?: string;
}
export function Sidebar({className}: SidebarProps) {
  let auth = useAuth();
  const {isSubscribed} = auth;
  const {billing} = useSettings();
  let path = useLocation();
  let [chat, setChat] = useState(false);
  let [modalOpen, setModalOpen] = useState(false);
  useEffect(()=>{
    if (path.pathname.includes('chat'))
      setChat(true);
    else
      setChat(false);
  }, [path.pathname])

  if (chat){
    return(
        <>
        <div className='block md:hidden'>
            <Inbox setModalOpen={setModalOpen}></Inbox>
            <AddChatModal open={modalOpen} setOpen={setModalOpen}></AddChatModal>
          </div>

        <div className='hidden md:block w-full'>
        <div
      className={clsx(
        className,
        'flex flex-col border-r lg:border-none lg:rounded-tr-[50px] lg:rounded-br-[50px] lg:py-24 py-0 bg-white dark:bg text-sm font-medium text-muted',
      )}
    >
      <img src={companyLogo} className='w-[129px] block mx-auto' alt="" />
      <div className='hidden lg:flex flex-col px-12 items-center mb-20'>
        <img src={auth.user?.image} alt="" className='w-50 h-50 rounded-full object-cover'/>
        <h3 className='text-black dark:text-white  mt-8'>{auth.user?.name}</h3>
        <h3 className='text-muted ml-10'>{auth.user?.email}</h3>
      </div>
      <div className="compact-scrollbar flex-auto overflow-y-auto">
        <CreateNewButton className="px-12 text-center" />
        <SidebarMenu />
        <StorageMeter />
        {billing.enable ? (
          <div className="mt-14 pl-60">
            <Button
              elementType={Link}
              to={isSubscribed ? '/billing/change-plan' : '/pricing'}
              variant="outline"
              color="primary"
              size="xs"
            >
              <Trans message="Upgrade" />
            </Button>
          </div>
        ) : null}
      </div>
      <WorkspaceSwitcher />
    </div>
        </div>
        </>
    );
  }
  return (
    <div
      className={clsx(
        className,
        'flex flex-col border-r lg:border-none lg:rounded-tr-[50px] lg:rounded-br-[50px] lg:py-24 py-0 bg-white dark:bg text-sm font-medium text-muted',
      )}
    >
      <img src={companyLogo} className='w-[129px] block mx-auto mb-0' alt="" />
      <div className='flex flex-col px-12 items-center mb-20'>
        <img src={auth.user?.image} alt="" className='w-50 h-50 rounded-full object-cover'/>
        <h3 className='text-black dark:text-white  mt-8'>{auth.user?.name}</h3>
        <h3 className='text-muted ml-10'>{auth.user?.email}</h3>
      </div>
      <div className="compact-scrollbar flex-auto overflow-y-auto">
        <CreateNewButton className="px-12 text-center" />
        <SidebarMenu />
        <StorageMeter />
        {billing.enable ? (
          <div className="mt-14 pl-60">
            <Button
              elementType={Link}
              to={isSubscribed ? '/billing/change-plan' : '/pricing'}
              variant="outline"
              color="primary"
              size="xs"
            >
              <Trans message="Upgrade" />
            </Button>
          </div>
        ) : null}
      </div>
      <WorkspaceSwitcher />
    </div>
  );
}

function WorkspaceSwitcher() {
  const navigate = useNavigate();
  return (
    <WorkspaceSelector
      onChange={() => {
        navigate(RootFolderPage.path);
      }}
      className="mt-auto w-full flex-shrink-0 border-t px-24 py-18"
    />
  );
}
