import { List, ListItem } from '@ui/list/list';
// import {PersonIcon} from '@ui/icons/material/Person';
import { Trans } from '@ui/i18n/trans';
// import {LoginIcon} from '@ui/icons/material/Login';
// import {LockIcon} from '@ui/icons/material/Lock';
// import {PhonelinkLockIcon} from '@ui/icons/material/PhonelinkLock';
// import {LanguageIcon} from '@ui/icons/material/Language';
// import {ApiIcon} from '@ui/icons/material/Api';
// import {DangerousIcon} from '@ui/icons/material/Dangerous';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
// import {DevicesIcon} from '@ui/icons/material/Devices';
import { useAuth } from '@common/auth/use-auth';
import { useSettings } from '@ui/settings/use-settings';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { useAllSocialLoginsDisabled } from '@common/auth/ui/use-all-social-logins-disabled';

export enum AccountSettingsId {
  AccountDetails = 'account-details',
  SocialLogin = 'social-login',
  Password = 'password',
  TwoFactor = 'two-factor',
  LocationAndLanguage = 'location-and-language',
  Developers = 'developers',
  DeleteAccount = 'delete-account',
  Sessions = 'sessions',
  nothing = '',
}

const sections = ['account-details', 'social-login', 'password', 'two-factor', 'location-and-language', 'developers', 'delete-account', 'sessions'];


export function AccountSettingsSidenav() {
  const p = AccountSettingsId;

  const { hasPermission } = useAuth();
  const { api } = useSettings();
  const { auth } = useContext(SiteConfigContext);
  let [dropDownOpened, setDropDownOpened] = useState(false);



  const allSocialsDisabled = useAllSocialLoginsDisabled();

  return (
    <aside className="sticky top-0 mb-5 hidden flex-shrink-0 lg:block z-50">
      <List padding="p-0" className='flex items-center justify-evenly bg-white dark:bg rounded-lg z-50 py-4 px-4 shadow-md'>
        {/* {auth.accountSettingsPanels?.map(panel => (
          <Item
            key={panel.id}
            icon={<panel.icon viewBox="0 0 50 50" />}
            panel={panel.id as AccountSettingsId}
          >
            <Trans {...panel.label} />
          </Item>
        ))} */}
        <Item panel={p.AccountDetails} className='user-settings-tap mb-0'>
          <Trans message="Account details" />
        </Item>
        {!allSocialsDisabled && (
          <Item panel={p.SocialLogin} className='user-settings-tap mb-0'>
            <Trans message="Social login" />
          </Item>
        )}
        <Item panel={p.Password} className='user-settings-tap mb-0'>
          <Trans message="Password" />
        </Item>
        <Item panel={p.TwoFactor} className='user-settings-tap mb-0'>
          <Trans message="Two factor authentication" />
        </Item>
        <Item panel={p.Sessions} className='user-settings-tap mb-0'>
          <Trans message="Active sessions" />
        </Item>
        <Item panel={p.LocationAndLanguage} className='user-settings-tap mb-0 hidden xl:block'>
          <Trans message="Location and language" />
        </Item>
        {api?.integrated && hasPermission('api.access') ? (
          <Item panel={p.Developers} className='user-settings-tap mb-0 hidden 2xl:block'>
            <Trans message="Developers" />
          </Item>
        ) : null}
        <Item panel={p.DeleteAccount} className='user-settings-tap mb-0 hidden 2xl:block'>
          <Trans message="Delete account" />
        </Item>

        <span onClick={() => setDropDownOpened(!dropDownOpened)}>
          <Item panel={p.nothing} className='user-settings-tap mb-0 flex justify-center items-center 2xl:hidden'>
            <span className='font-bold content-center text-center'><Trans message="..." /></span>
          </Item>
        </span>
      </List>
      <div className={`absolute right-0 py-[5px] ${dropDownOpened ? 'block' : 'hidden'}`}>
        <List padding="p-0" className='flex flex-col items-center justify-evenly bg-white dark:bg rounded-lg z-50 py-4 px-4 shadow-md'>

          <span onClick={() => setDropDownOpened(!dropDownOpened)}>
            <Item panel={p.LocationAndLanguage} className='user-settings-tap mb-0 block xl:hidden'>
              <Trans message="Location and language" />
            </Item>
          </span>
          {api?.integrated && hasPermission('api.access') ? (
            <span onClick={() => setDropDownOpened(!dropDownOpened)}>
              <Item panel={p.Developers} className='user-settings-tap mb-0 block 2xl:hidden'>
                <Trans message="Developers" />
              </Item>
            </span>
          ) : null}
          <span onClick={() => setDropDownOpened(!dropDownOpened)}>
            <Item panel={p.DeleteAccount} className='user-settings-tap mb-0 block 2xl:hidden'>
              <Trans message="Delete account" />
            </Item>
          </span>
        </List>
      </div>
    </aside>
  );
}

interface ItemProps {
  children: ReactNode;
  icon?: ReactNode;
  isLast?: boolean;
  panel: AccountSettingsId;
  className?: string;
}
function Item({ children, icon, isLast, panel, className }: ItemProps) {
  return (
    <ListItem
      startIcon={icon}
      className={isLast ? className : `${className} mb-10`}
      panel={panel}
      onSelected={() => {
        const panelEl = document.querySelector(`#${panel}`);
        if (panelEl) {
          panelEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }}
    >
      {children}
    </ListItem>
  );
}
