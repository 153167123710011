import { configureStore } from '@reduxjs/toolkit'
import rightSideNavReducer from './slices/rightSideNavSlice'
import chatReducer from './slices/chatSlice'

export const store = configureStore({
    reducer: {
        rightSideNav: rightSideNavReducer,
        chat: chatReducer
    }
})

export type RootState = ReturnType<typeof store.getState>;