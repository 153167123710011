import clsx from 'clsx';
import {LandingPageContent} from './landing-page-content';
// import {Navbar} from '@common/ui/navigation/navbar/navbar';
// import {Button, ButtonProps} from '@ui/buttons/button';
import {IconButton} from '@ui/buttons/icon-button';
import {KeyboardArrowDownIcon} from '@ui/icons/material/KeyboardArrowDown';
import {Footer} from '@common/ui/footer/footer';
import {Trans} from '@ui/i18n/trans';
import {AdHost} from '@common/admin/ads/ad-host';
// import {Link} from 'react-router-dom';
import {createSvgIconFromTree} from '@ui/icons/create-svg-icon';
import {MenuItemConfig} from '@common/menus/menu-config';
import {Fragment, useEffect, useState} from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';
import {useTrans} from '@ui/i18n/use-trans';
import {useSettings} from '@ui/settings/use-settings';
import {MixedImage} from '@ui/images/mixed-image';
import companyLogo from './images/company-logo.png';
import heroBg from './images/BG-DNcWQDN9.png';
import heroImgLg from './images/Mockup shadow-59lZxPa7.png';
import heroImgSm from './images/Mockup shadow Mobile-B9s5EjEw.png';
import logos from './images/Logos-B1S2Pc1A.png';
import shareImg from './images/feat1-fNkW-c-2.png';
import chartImg from './images/feat2-D2ozoEcv.png';
import lineImg from './images/feat3-Bjz4KxBl.png';
import React from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button, NextUIProvider} from "@nextui-org/react";
import PlansCard from '@app/PlansCard';
import { apiClient } from '@common/http/query-client';
import { useNavigate } from '@common/ui/navigation/use-navigate';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';
import { UpsellBillingCycle } from '@common/billing/pricing-table/find-best-price';

interface ContentProps {
  content: LandingPageContent;
}
export function LandingPage() {
  const settings = useSettings();
  const homepage = settings.homepage as {appearance: LandingPageContent};

  
  

  return (
    <Fragment>
      <NextUIProvider>
        <DefaultMetaTags />
        <div>
          <Nav/>
          <HeroHeader/>
          <Solutions/>
          <Pricing/>
          {/* <AdHost slot="landing-top" className="mb-14 px-14 md:mb-60" />
          <PrimaryFeatures content={homepage.appearance} />
          <div className="my-40 h-1 bg-divider" />
          <SecondaryFeatures content={homepage.appearance} />
          <BottomCta content={homepage.appearance} />
          <Footer className="landing-container" /> */}
        </div>
      </NextUIProvider>
    </Fragment>
  );
}

function HeroHeader() {
  return (
    <>
      
      <header className='hero-bg min-h-screen flex w-full items-center justify-center'>
        <div className='container'>
            <div className='flex justify-center items-center flex-col pt-50 md:p-[50px] pb-0'>
              <h1 className='font-[600] text-[48px] md:text-[90px] text-center max-w-[974px] leading-[48px] md:leading-[108px] text-[#222E57] dark:text-white'>Effortless Cloud <span className='text-gradient-pink'>Storage</span> for Your Business</h1>
              <p className='max-w-[974px] text-[18px] text-[#888888] text-center mt-32 font-[300] md:font-[400]'>Whether you need a quick solution or have specific processes that need to be optimised,we are your partner to implement Odoo solutions.</p>
            </div>
            <form className='mt-48 md:mt-0 mb-50 px-20 flex flex-wrap w-full items-center justify-center gap-8'>
                <input type="email" placeholder='enter your email here' name='freeTrialEmail' id='freeTrialEmail' className='text-black dark:text-white bg-[#EAEBF0] w-full md:w-[353px] h-[48px] py-8 px-24 rounded-[12px] border'/>
                <button className='text-white w-full md:w-[159px] h-[48px] rounded-[12px] bg-primary text-[16px] font-[500]'>Start Free Trial</button>
              </form>
            <img src={heroImgLg} className='max-w-[1000px] hidden lg:block mx-auto' loading='lazy' alt="candy cloudy website drive page" />
            <img src={heroImgSm} className='block lg:hidden mx-auto' loading='lazy' alt="candy cloudy website drive page" />
            <div className='py-40 lg:px-80'>
            <div className="overflow-x-hidden w-full ant-flex css-pvzb04 ant-flex-justify-center">
              <img src={logos} className="h-[30px] !w-[1308px] partener-anim" alt="Partners" />
              <img src={logos} className="h-[30px] !w-[1308px] partener-anim" alt="Partners" />
              <img src={logos} className="h-[30px] !w-[1308px] partener-anim" alt="Partners" />
            </div>
            </div>

        </div>
      </header>
    </>
  );
}


function Nav(){
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  let navigate = useNavigate();

  // Function to update the scroll position
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  // useEffect to attach the event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 
  

  return (
    <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} className={`lg:py-10 delay-200 duration-200 ${scrollPosition===0 ? 'hero-bg' : 'bg-white dark:bg shadow-md'}`}>
      <NavbarContent justify='start'>
        <NavbarBrand className=''>
          <img src={companyLogo} width={129} alt="" />
        </NavbarBrand>
        
      </NavbarContent>
      <NavbarContent justify='end' className='lg:hidden flex'>
      <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="lg:hidden me-5"
          icon={()=>{
            if (!isMenuOpen)
              return <i className='fa-solid fa-bars'></i>;
            else
              return <i className='fa-solid fa-x'></i>
          }}
        />
        </NavbarContent>

      <NavbarContent className="hidden lg:flex gap-50 font-[500]" justify="center">
        <NavbarItem>
          <Link className='text-[#24336A] dark:text-white' href="#">
            Solutions
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className='text-[#24336A] dark:text-white'  href="#">
            About Us
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className='text-[#24336A] dark:text-white' href="#">
            Pricing
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link className='text-[#24336A] dark:text-white' href="/contact">
            Contact Us
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end" className='hidden lg:flex'>
        {/* <NavbarItem className="hidden lg:flex items-center justify-center bg-primary w-[135px] h-[48px] rounded-[12px] py-12 px-24">
          <Link href="#" className='text-white font-[500] text-center'>Login</Link>
        </NavbarItem> */}
        <NavbarItem>
          <button onClick={()=>navigate('/register')} className='hover:bg-primary! hover:opacity-100! font-[500] text-center hidden lg:flex items-center justify-center bg-primary w-[135px] h-[48px] rounded-[12px] py-12 px-24 text-white'>
            Sign Up
          </button>
        </NavbarItem>
        <NavbarItem>
          <button onClick={()=>navigate('/login')} className='hover:bg-primary! hover:opacity-100! font-[500] text-center hidden lg:flex items-center justify-center bg-primary w-[135px] h-[48px] rounded-[12px] py-12 px-24 text-white'>
            Sign in
          </button>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu>
        <NavbarMenuItem className='mt-8 cursor-pointer w-fit' onClick={()=>setIsMenuOpen(false)}>
          <div className='text-[#24336A] dark:text-white'>Solutions</div>
        </NavbarMenuItem>
        <NavbarMenuItem className='mt-8 cursor-pointer w-fit' onClick={()=>setIsMenuOpen(false)}>
          <div className='text-[#24336A] dark:text-white'>About Us</div>
        </NavbarMenuItem>
        <NavbarMenuItem className='mt-8 cursor-pointer w-fit' onClick={()=>setIsMenuOpen(false)}>
          <div className='text-[#24336A] dark:text-white'>Pricing</div>
        </NavbarMenuItem>
        <NavbarMenuItem className='mt-8 cursor-pointer w-fit' onClick={()=>{setIsMenuOpen(false); navigate('/contact');}}>
          <div className='text-[#24336A] dark:text-white'>Contact Us</div>
        </NavbarMenuItem>
        <NavbarMenuItem className='mt-8 cursor-pointer w-fit' onClick={()=>{setIsMenuOpen(false); navigate('/register')}}>
          <div className='text-[#24336A] dark:text-white'>Sign up</div>
        </NavbarMenuItem>
        <NavbarMenuItem className='mt-8 cursor-pointer w-fit' onClick={()=>{setIsMenuOpen(false); navigate('/login')}}>
          <div className='text-[#24336A] dark:text-white'>Sign in</div>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}

function Solutions(){

  return (
    <div className='py-80 px-0 lg:px-80'>
      <h1 className='max-w-[764px] text-[#222E57] dark:text-white font-[600] text-[56px] leading-[67.2px] text-center block mx-auto'><span className='text-gradient-pink'>Secure<span className='text-[#222E57] dark:text-white'>.</span> Scalable<span className='text-[#222E57] dark:text-white'>.</span></span> Your Cloud Advantage</h1>
      <p className='max-w-[764px] text-[18px] leading-[27px] text-center text-[#888888] block mx-auto mt-32'>Secure, scalable, and tailored to empower your endeavors, the cloud offers unparalleled advantages in today's digital landscape. With robust security measures in place.</p>
      <div className='w-full mt-80'>
      <div className='flex flex-wrap'>
        <div className='md:w-1/2 w-full p-20'>
          <div className='w-full'>
            <img src={shareImg} alt="share dialog image" loading='lazy' className='w-full'/>
          </div>
        </div>
        <div className='md:w-1/2 w-full p-20'>
          <div className='w-full text-black dark:text-white h-full flex flex-col'>
            <h3 className='text-[24px] font-[500]'>Collaborative Workspaces and Version Control</h3>
            <div className='w-full border flex-grow flex flex-col justify-between p-24 rounded-[24px] mt-20'>
              <div>
                <h4 className='font-[500]'>Real-Time Collaboration</h4>
                <p className='opacity-60'>Enable seamless collaboration among team members by allowing them to simultaneously edit documents, share feedback, and work together in real-time.</p>
              </div>
              <div className='mt-20'>
                <h4 className='font-[500]'>Version History and Rollback</h4>
                <p className='opacity-60'>Maintain a detailed version history for files, enabling users to track changes, revert to previous versions, and ensure that the latest edits are always accessible.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap mt-80'>
        
        <div className='md:w-1/2 w-full p-20'>
          <div className='w-full text-black dark:text-white h-full flex flex-col'>
            <h3 className='text-[24px] font-[500]'>Scalable Storage Solutions</h3>
            <div className='w-full border flex-grow flex flex-col justify-between p-24 rounded-[24px] mt-20'>
              <div>
                <h4 className='font-[500]'>Flexible Storage Plans</h4>
                <p className='opacity-60'>Choose from a range of storage plans to accommodate your specific needs, allowing you to scale your storage requirements as your business evolves.</p>
              </div>
              <div className='mt-20'>
                <h4 className='font-[500]'>Pay-as-You-Go Model</h4>
                <p className='opacity-60'>Benefit from a cost-effective pay-as-you-go pricing model, ensuring you only pay for the storage resources you consume.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='md:w-1/2 w-full p-20'>
          <div className='w-full'>
            <img src={chartImg} alt="share dialog image" loading='lazy' className='w-full'/>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap mt-80'>
        <div className='md:w-1/2 w-full p-20'>
          <div className='w-full'>
            <img src={lineImg} alt="share dialog image" loading='lazy' className='w-full'/>
          </div>
        </div>
        <div className='md:w-1/2 w-full p-20'>
          <div className='w-full text-black dark:text-white h-full flex flex-col'>
            <h3 className='text-[24px] font-[500]'>Pre-Built Integrations</h3>
            <div className='w-full border flex-grow flex flex-col justify-between p-24 rounded-[24px] mt-20'>
              <div>
                <h4 className='font-[500]'>Collaboration Tools</h4>
                <p className='opacity-60'>Seamless integration with platforms like Slack, Microsoft Teams, or Asana for streamlined teamwork and communication.</p>
              </div>
              <div className='mt-20'>
                <h4 className='font-[500]'>Project Management Solutions</h4>
                <p className='opacity-60'>Integrate with project management tools like Jira or Trello, ensuring a unified workflow for project teams.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

function Pricing(){
  const [activeBtn, setActiveBtn] = useState(0);
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');

  return(
    <div className='flex flex-col w-full items-center p-80 bg-[#FAFAFB] dark:bg'>
      <h1 className='text-[#24336A] dark:text-white font-[600] text-[56px] leading-[67.2px] text-center max-w-[784px]'>Choose your Plan. Try it <span className='text-gradient-pink'>free</span> for 7 days.</h1>
      <p className='text-center font-[500] text-[14px] text-[#888888] mt-24'>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</p>
      <div className='mt-48 mx-auto bg-[#0154A01A] w-[288px] h-[57px] p-8 rounded-[24px] flex items-center justify-center gap-8'>
      <button className={`header-button ${selectedCycle === 'monthly' ? 'active' : ''}`} onClick={()=>setSelectedCycle('monthly')}>Monthly billing</button>
      <button className={`header-button ${selectedCycle === 'yearly' ? 'active' : ''}`} onClick={()=>setSelectedCycle('yearly')}>Annual billing</button>
      </div>
      <div className='mt-24'>
          <div className='flex flex-wrap justify-center'>
              {/* <PlansCard price='9.00' storage={'50'} period={activeBtn===0?'month':'year'}></PlansCard>
              <PlansCard price='12.00' storage={'100'} period={activeBtn===0?'month':'year'} padge='Most Popular'></PlansCard>
              <PlansCard price='20.00' storage={'300'} period={activeBtn===0?'month':'year'}></PlansCard> */}
          </div>
      </div>
      <PricingTable
                            selectedCycle={selectedCycle}
                            productLoader="pricingPage"
                          />
    </div>
  );
}

// interface CtaButtonProps extends ButtonProps {
//   item?: MenuItemConfig;
// }
// function CtaButton({item, ...buttonProps}: CtaButtonProps) {
//   if (!item?.label) return null;
//   const Icon = item.icon ? createSvgIconFromTree(item.icon) : undefined;
//   return (
//     <Button
//       elementType={item.type === 'route' ? Link : 'a'}
//       href={item.action}
//       to={item.action}
//       startIcon={Icon ? <Icon /> : undefined}
//       {...buttonProps}
//     >
//       <Trans message={item.label} />
//     </Button>
//   );
// }

// function PrimaryFeatures({content}: ContentProps) {
//   return (
//     <div
//       className="landing-container items-stretch gap-26 md:flex"
//       id="primary-features"
//     >
//       {content?.primaryFeatures?.map((feature, index) => (
//         <div
//           key={index}
//           className="mb-14 flex-1 rounded-2xl px-24 py-36 text-center shadow-[0_10px_30px_rgba(0,0,0,0.08)] md:mb-0"
//           data-testid={`primary-root-${index}`}
//         >
//           <MixedImage
//             className="mx-auto mb-30 h-128"
//             data-testid={`primary-image-${index}`}
//             src={feature.image}
//           />
//           <h2
//             className="my-16 text-lg font-medium"
//             data-testid={`primary-title-${index}`}
//           >
//             <Trans message={feature.title} />
//           </h2>
//           <div
//             className="text-md text-[0.938rem]"
//             data-testid={`primary-subtitle-${index}`}
//           >
//             <Trans message={feature.subtitle} />
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// }

// function SecondaryFeatures({content}: ContentProps) {
//   return (
//     <div className="landing-container">
//       {content?.secondaryFeatures?.map((feature, index) => {
//         const isEven = index % 2 === 0;
//         return (
//           <div
//             key={index}
//             data-testid={`secondary-root-${index}`}
//             className={clsx(
//               'mb-14 py-16 md:mb-80 md:flex',
//               isEven && 'flex-row-reverse',
//             )}
//           >
//             <img
//               src={feature.image}
//               className="mr-auto w-580 max-w-full rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.08)]"
//               data-testid={`secondary-image-${index}`}
//               alt=""
//             />
//             <div className="ml-30 mr-auto max-w-350 pt-30">
//               <small
//                 className="mb-16 text-xs font-medium uppercase tracking-widest text-muted"
//                 data-testid={`secondary-subtitle-${index}`}
//               >
//                 <Trans message={feature.subtitle} />
//               </small>
//               <h3
//                 className="py-16 text-3xl"
//                 data-testid={`secondary-title-${index}`}
//               >
//                 <Trans message={feature.title} />
//               </h3>
//               <div className="h-2 w-50 bg-black/90" />
//               <div
//                 className="my-20 text-[0.938rem]"
//                 data-testid={`secondary-description-${index}`}
//               >
//                 <Trans message={feature.description} />
//               </div>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// }

// function BottomCta({content}: ContentProps) {
//   return (
//     <div
//       className="relative bg-[#2B2B2B] bg-no-repeat py-70 text-center text-on-primary md:bg-fixed"
//       style={{backgroundImage: `url("${content.footerImage}")`}}
//       data-testid="footerImage"
//     >
//       <h2
//         className="mx-auto max-w-620 text-3xl font-normal"
//         data-testid="footerTitle"
//       >
//         <Trans message={content.footerTitle} />
//       </h2>
//       {content.footerSubtitle && (
//         <p
//           className="mx-auto mt-50 max-w-620 text-2xl font-normal"
//           data-testid="footerSubtitle"
//         >
//           <Trans message={content.footerSubtitle} />
//         </p>
//       )}
//       <CtaButton
//         item={content.actions?.cta3}
//         size="lg"
//         variant="outline"
//         color="paper"
//         className="mt-50 block"
//         data-testid="cta3"
//       />
//     </div>
//   );
// }
