import React from 'react'

export default function GearIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.59396 3.94C9.68396 3.398 10.154 3 10.704 3H13.297C13.847 3 14.317 3.398 14.407 3.94L14.62 5.221C14.683 5.595 14.933 5.907 15.265 6.091C15.339 6.131 15.412 6.174 15.485 6.218C15.81 6.414 16.205 6.475 16.56 6.342L17.777 5.886C18.0264 5.79221 18.301 5.78998 18.5518 5.87971C18.8027 5.96945 19.0136 6.14531 19.147 6.376L20.443 8.623C20.576 8.8537 20.6229 9.12413 20.5753 9.38617C20.5277 9.6482 20.3887 9.88485 20.183 10.054L19.18 10.881C18.887 11.122 18.742 11.494 18.75 11.873C18.7514 11.958 18.7514 12.043 18.75 12.128C18.742 12.506 18.887 12.878 19.18 13.119L20.184 13.946C20.608 14.296 20.718 14.901 20.444 15.376L19.146 17.623C19.0128 17.8536 18.8022 18.0296 18.5515 18.1195C18.3008 18.2094 18.0263 18.2074 17.777 18.114L16.56 17.658C16.205 17.525 15.81 17.586 15.484 17.782C15.4115 17.8261 15.3381 17.8688 15.264 17.91C14.933 18.093 14.683 18.405 14.62 18.779L14.407 20.06C14.317 20.603 13.847 21 13.297 21H10.703C10.153 21 9.68396 20.602 9.59296 20.06L9.37996 18.779C9.31796 18.405 9.06796 18.093 8.73596 17.909C8.66181 17.8681 8.58846 17.8258 8.51596 17.782C8.19096 17.586 7.79596 17.525 7.43996 17.658L6.22296 18.114C5.97369 18.2075 5.69933 18.2096 5.44866 18.1199C5.19799 18.0302 4.98727 17.8545 4.85396 17.624L3.55696 15.377C3.4239 15.1463 3.37701 14.8759 3.42462 14.6138C3.47223 14.3518 3.61125 14.1152 3.81696 13.946L4.82096 13.119C5.11296 12.879 5.25796 12.506 5.25096 12.128C5.2494 12.043 5.2494 11.958 5.25096 11.873C5.25796 11.493 5.11296 11.122 4.82096 10.881L3.81696 10.054C3.6115 9.88489 3.47264 9.64843 3.42503 9.38662C3.37743 9.12481 3.42418 8.8546 3.55696 8.624L4.85396 6.377C4.98715 6.14614 5.19797 5.97006 5.44887 5.88014C5.69977 5.79021 5.97445 5.79229 6.22396 5.886L7.43996 6.342C7.79596 6.475 8.19096 6.414 8.51596 6.218C8.58796 6.174 8.66196 6.132 8.73596 6.09C9.06796 5.907 9.31796 5.595 9.37996 5.221L9.59396 3.94Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}
