import { useProducts } from './use-products';
import { Button } from '@ui/buttons/button';
import { Trans } from '@ui/i18n/trans';
import { ForumIcon } from '@ui/icons/material/Forum';
import { Navbar } from '../../ui/navigation/navbar/navbar';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { Footer } from '../../ui/footer/footer';
import { Fragment, useMemo, useState } from 'react';
import { UpsellBillingCycle } from './find-best-price';
import { BillingCycleRadio } from './billing-cycle-radio';
import { StaticPageTitle } from '../../seo/static-page-title';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';
import { FileUploadStoreOptions } from '@common/uploads/uploader/file-upload-store';
import { getActiveWorkspaceId } from '@common/workspace/active-workspace-id';
import { useActiveWorkspaceId } from '@common/workspace/active-workspace-id-context';
import { useDriveStore } from '@app/drive/drive-store';
import { useLogout } from '@common/auth/requests/logout';
import { useAuth } from '@common/auth/use-auth';
import { FileUploadProvider } from '@common/uploads/uploader/file-upload-provider';
import { FileEntryUrlsContext } from '@common/uploads/file-entry-urls';
import ChatIcon from '@app/drive/layout/icons/ChatIcon';
import PricePlansIcon from '@app/drive/layout/icons/PricePlansIcon';
import GearIcon from '@app/drive/layout/icons/GearIcon';
import SignoutIcon from '@app/drive/layout/icons/SignoutIcon';
import { DashboardLayout } from '@common/ui/dashboard-layout/dashboard-layout';
import { DashboardSidenav } from '@common/ui/dashboard-layout/dashboard-sidenav';
import { Sidebar } from '@app/drive/layout/sidebar/sidebar';
import { DashboardContent } from '@common/ui/dashboard-layout/dashboard-content';
import { UploadQueue } from '@app/drive/uploading/upload-queue';
import { EntryDragPreview } from '@app/drive/file-view/entry-drag-preview';
import { useThemeSelector } from '@ui/themes/theme-selector-context';
import { useSettings } from '@ui/settings/use-settings';
import ThemeSwitch from '@app/drive/layout/ThemeSwitch';

const uploadStoreOptions: FileUploadStoreOptions = {
  modifyUploadedFile: uploadedFile => {
    const workspaceId = getActiveWorkspaceId();
    uploadedFile.fingerprint = `${uploadedFile.fingerprint}-w-${workspaceId}`;
    return uploadedFile;
  },
};

export function PricingPage() {
  const query = useProducts('pricingPage');
  const [selectedCycle, setSelectedCycle] =
    useState<UpsellBillingCycle>('yearly');

  const { pathname } = useLocation();
  const { hash } = useParams();
  const { workspaceId } = useActiveWorkspaceId();
  const activePage = useDriveStore(s => s.activePage);
  const { mutate: logout } = useLogout();
  const [activeBtn, setActiveBtn] = useState(0);
  let auth = useAuth();
  const {selectedTheme, selectTheme} = useThemeSelector();
  const {themes} = useSettings();

  function handleTheme(){
    if (themes?.user_change && !selectedTheme.is_dark){
      selectTheme('dark');
    }
    else if (themes?.user_change && selectedTheme.is_dark){
      selectTheme('light');
    }
  }

  const urlsContextValue = useMemo(() => {
    return { workspaceId };
  }, [workspaceId]);

  return (
    <Fragment>
      {activePage?.label && (
        <StaticPageTitle>
          <Trans
            message={
              typeof activePage.label === 'string'
                ? activePage.label
                : activePage.label.message
            }
          />
        </StaticPageTitle>
      )}
      <FileUploadProvider options={uploadStoreOptions}>
        <FileEntryUrlsContext.Provider value={urlsContextValue}>
          <div className='flex w-full bg-main'>
            <div className='h-[100vh] min-w-[90px] py-20 hidden lg:flex flex-col justify-between items-center'>
              <div className="flex flex-col items-center justify-center gap-20">
                {auth.hasRole(1) ? <></> : <NavLink to={'/admin'} className={'side-tabs-icon'}>
                  <i className="fa-solid fa-user-tie "></i>
                </NavLink>}
                <NavLink to={'/drive'} className={'side-tabs-icon'}>
                  <i className="fa-regular fa-folder-open "></i>
                </NavLink>
                <NavLink to={'/chat'} className={'side-tabs-icon'}>
                  <ChatIcon></ChatIcon>
                </NavLink>
                <NavLink to={'/pricing'} className={'side-tabs-icon'}>
                  <PricePlansIcon></PricePlansIcon>
                </NavLink>
                <NavLink to={'/account-settings'} className={'side-tabs-icon'}>
                  <GearIcon></GearIcon>
                </NavLink>

              </div>
              <div className='flex flex-col items-center gap-20'>
                  <ThemeSwitch onChange={handleTheme} isSelected={selectedTheme.is_dark}></ThemeSwitch>
                <button className='cursor-pointer' onClick={()=>logout()}>
                    <SignoutIcon></SignoutIcon>
                </button>
                </div>
            </div>
            <div className='flex-grow ms-auto lg:rounded-tl-[100px] lg:rounded-bl-[100px] overflow-x-hidden bg-alt'>
              <DashboardLayout
                name="drive"
                onDragOver={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.dataTransfer.dropEffect = 'none';
                }}
                onDrop={e => {
                  // prevent files from desktop from blowing away the document
                  e.preventDefault();
                }}
              >
                {/* <NavbarSearch /> */}
                <span className='fixed left-0 right-0'>
                  <Navbar/>
                {/* <Navbar
                        color="bg"
                        darkModeColor="transparent"
                        border="border-b"
                        menuPosition="pricing-table-page"
                      /> */}
                </span>
                <DashboardSidenav position="left" size="md">
                  <Sidebar />
                </DashboardSidenav>
                {/* <DriveContentHeader /> */}

                <DashboardContent>
                  <div className='py-32 px-24 mt-48 md:mt-0 plans'>
                    <Fragment>
                      <StaticPageTitle>
                        <Trans message="Pricing" />
                      </StaticPageTitle>
                      {/* <Navbar
                        color="bg"
                        darkModeColor="transparent"
                        border="border-b"
                        menuPosition="pricing-table-page"
                      /> */}
                      <div className="container mx-auto overflow-auto bg-alt px-24">
                        <h1 className="text-darkBlue dark:text-white text-center text-3xl font-normal md:text-4xl md:font-medium">
                          <Trans message="Pricing Plans" />
                        </h1>
                        <p className='text-center text-[14px] text-[#888888] mb-30 mt-8'>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</p>

                        <div>
                        <div className={`my-24 mx-auto bg-[#0154A01A] w-[288px] h-[57px] p-8 rounded-[24px] flex items-center justify-center gap-8`}>
                            <button className={`header-button ${selectedCycle === 'monthly' ? 'active' : ''}`} onClick={()=>setSelectedCycle('monthly')}>Monthly billing</button>
                            <button className={`header-button ${selectedCycle === 'yearly' ? 'active' : ''}`} onClick={()=>setSelectedCycle('yearly')}>Annual billing</button>
                        </div>
                        {/* <BillingCycleRadio
                          products={query.data?.products}
                          selectedCycle={selectedCycle}
                          onChange={setSelectedCycle}
                          className="mb-24 flex justify-center"
                          size="lg"
                        /> */}
                        </div>

                        <hr className='mb-32'/>

                          <PricingTable
                            selectedCycle={selectedCycle}
                            productLoader="pricingPage"
                          />
                        {/* <ContactSection /> */}
                      </div>
                      {/* <Footer className="container mx-auto flex-shrink-0 px-24" /> */}
                    </Fragment>
                  </div>
                </DashboardContent>
                <UploadQueue />

                {/* <DriveDialogsContainer /> */}
                {/* <DashboardSidenav position="right" size="lg">
                  <DetailsSidebar />
                </DashboardSidenav> */}
              </DashboardLayout>
            </div>
          </div>
        </FileEntryUrlsContext.Provider>
        <EntryDragPreview />
      </FileUploadProvider>
    </Fragment>
  );

  // return (
  //   <Fragment>
  //     <StaticPageTitle>
  //       <Trans message="Pricing" />
  //     </StaticPageTitle>
  //     <Navbar
  //       color="bg"
  //       darkModeColor="transparent"
  //       border="border-b"
  //       menuPosition="pricing-table-page"
  //     />
  //     <div className="container mx-auto overflow-auto bg-alt px-24">
  //       <h1 className="mb-30 mt-30 text-center text-3xl font-normal md:mt-60 md:text-4xl md:font-medium">
  //         <Trans message="Choose the right plan for you" />
  //       </h1>

  //       <BillingCycleRadio
  //         products={query.data?.products}
  //         selectedCycle={selectedCycle}
  //         onChange={setSelectedCycle}
  //         className="mb-40 flex justify-center md:mb-70"
  //         size="lg"
  //       />

  //       <span className='bg-green-700'>
  //         <PricingTable
  //           selectedCycle={selectedCycle}
  //           productLoader="pricingPage"
  //         />
  //       </span>
  //       {/* <ContactSection /> */}
  //     </div>
  //     {/* <Footer className="container mx-auto flex-shrink-0 px-24" /> */}
  //   </Fragment>
  // );
}

function ContactSection() {
  return (
    <div className="my-20 p-24 text-center md:my-80">
      <ForumIcon size="xl" className="text-muted" />
      <div className="my-8 md:text-lg">
        <Trans message="Do you have any questions about PRO accounts?" />
      </div>
      <div className="mb-24 mt-20 text-sm md:mt-0 md:text-base">
        <Trans message="Our support team will be happy to assist you." />
      </div>
      <Button variant="flat" color="primary" elementType={Link} to="/contact">
        <Trans message="Contact us" />
      </Button>
    </div>
  );
}
