import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { useDropzone } from 'react-dropzone';
import { Theme } from 'emoji-picker-react';
import { useThemeSelector } from '@ui/themes/theme-selector-context';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/lib/store';
import { sendMessage } from './API';
import { getFileCategory } from './functions';
import axios from 'axios';

const ChatInput = () => {
  const [message, setMessage] = useState('');
  const [emojiVisible, setEmojiVisible] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const emojiButtonRef = useRef<HTMLButtonElement | null>(null); 
  let { currentReciever } = useSelector((state: RootState) => state.chat);
  let [uploading, setUploading] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    setUploading(true);
    console.log(acceptedFiles);
    acceptedFiles.forEach(file => {
      
      // Call your API to send the file
      console.log(getFileCategory(file.name));
      
      sendMessage({ id: currentReciever?.id || 0, message: '', file, type: getFileCategory(file.name) || '' })
      .then(data=>{console.log(data); setUploading(false);})
      .catch(data=>{console.error(data); setUploading(false);})
      console.log('File sent:', file);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const send = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && message != '') {
      console.log('Message sent:', message);
      sendMessage({id: currentReciever?.id || 0, message, type:'image'});
      setMessage('');
    }
  };

  const handleFilePickerClick = () => {
    fileInputRef.current?.click();
  };

  const { selectedTheme } = useThemeSelector();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click is outside the emoji button and emoji picker
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node) &&
        emojiButtonRef.current &&
        !emojiButtonRef.current.contains(e.target as Node)
      ) {
        setEmojiVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [containerRef, emojiButtonRef]);

  return (
    <div ref={containerRef} className='max-w-[86vw]'>
      
        {emojiVisible && (
          <div className="absolute translate-y-[-100%] md:right-44">
            <EmojiPicker
              theme={selectedTheme.is_dark ? Theme.DARK : Theme.LIGHT}
              onEmojiClick={(emojiData) => {
                setMessage((prev) => prev + emojiData.emoji);
              }}
              className='w-[200px]'
              width={300}
            />
            </div>
          )}
      <div className="bg-white dark:bg py-12 px-24 rounded-[16px] flex items-center">
        <div {...getRootProps()}>
          <input {...getInputProps()} ref={fileInputRef} style={{ display: 'none' }} />
        </div>
        <input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Write your message here..."
          className="placeholder:text-sm bg-transparent outline-none text-black dark:text-[#888888] flex-grow"
          onKeyDown={send}
          onFocus={() => setEmojiVisible(false)}
        />
        <button
          ref={emojiButtonRef}
          onClick={(e) => {
            e.stopPropagation();
            setEmojiVisible((prev) => !prev);
          }}
        >
          <EmojieIcon />
        </button>
        <button onClick={handleFilePickerClick} className="ml-16">
          {uploading ? <i className='fa-solid fa-circle-notch fa-spin dark:text-white text-black'></i> : <FilePickerIcon />}
        </button>
        <button className='ml-16' onClick={()=>{
          if (message != ''){
            sendMessage({id: currentReciever?.id || 0, message, type:'image'});
            setMessage('');
          }
        }}>
        <i className="fa-regular fa-paper-plane text-[#888888] text-xl"></i>
        </button>
      </div>
    </div>
  );
};

export default ChatInput;

function EmojieIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.182 15.182C14.7641 15.5999 14.2681 15.9313 13.7221 16.1575C13.1761 16.3836 12.591 16.5 12 16.5C11.409 16.5 10.8239 16.3836 10.2779 16.1575C9.73194 15.9313 9.23586 15.5999 8.818 15.182M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12ZM9.75 9.75C9.75 10.164 9.582 10.5 9.375 10.5C9.168 10.5 9 10.164 9 9.75C9 9.336 9.168 9 9.375 9C9.582 9 9.75 9.336 9.75 9.75ZM9.375 9.75H9.383V9.765H9.375V9.75ZM15 9.75C15 10.164 14.832 10.5 14.625 10.5C14.418 10.5 14.25 10.164 14.25 9.75C14.25 9.336 14.418 9 14.625 9C14.832 9 15 9.336 15 9.75ZM14.625 9.75H14.633V9.765H14.625V9.75Z"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function FilePickerIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.375 12.7388L10.682 20.4318C9.83811 21.2757 8.69351 21.7498 7.50003 21.7498C6.30655 21.7498 5.16195 21.2757 4.31803 20.4318C3.47411 19.5878 3 18.4432 3 17.2498C3 16.0563 3.47411 14.9117 4.31803 14.0678L15.258 3.12777C15.5367 2.84923 15.8675 2.62831 16.2315 2.47762C16.5956 2.32693 16.9857 2.24942 17.3797 2.24951C17.7737 2.2496 18.1639 2.3273 18.5278 2.47816C18.8918 2.62903 19.2225 2.8501 19.501 3.12877C19.7796 3.40743 20.0005 3.73823 20.1512 4.10227C20.3019 4.46631 20.3794 4.85647 20.3793 5.25047C20.3792 5.64447 20.3015 6.03459 20.1506 6.39857C19.9998 6.76254 19.7787 7.09323 19.5 7.37177L8.55203 18.3198C8.26801 18.592 7.88839 18.7408 7.49497 18.7358C7.10156 18.7309 6.72585 18.5715 6.44883 18.2921C6.1718 18.0127 6.01564 17.6357 6.01399 17.2422C6.01234 16.8488 6.16535 16.4705 6.44003 16.1888L14.25 8.37877M8.56103 18.3098L8.55103 18.3198"
        stroke="#888888"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
