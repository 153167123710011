import React, { useEffect } from 'react'
import ImageCircle from './ImageCircle';
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentChatReciever } from '@app/lib/slices/chatSlice';
import { RootState } from '@app/lib/store';
// import { user } from '@nextui-org/react';



interface inboxItemProbs{
    avatar: string;
    name: string;
    time?: string;
    displayedMessage?: string;
    active?: boolean;
    id: number;
    user: User;
}

export default function InboxItem({avatar, name, time, displayedMessage, active=false, id, user}: inboxItemProbs) {
  
  const dispatch = useDispatch();
  let { currentReciever } = useSelector((state: RootState) => state.chat);
  if (user.id===currentReciever?.id){
    active=true;
  }
  else{
    active=false;
  }

  useEffect(()=>{
    console.log(currentReciever);
    
  }, [currentReciever])

  const handleClick = () => {
    dispatch(setCurrentChatReciever(user));
  };

  return (
    <div className={`${active ? 'bg-[#0154A01A]' : 'hover:bg-alt cursor-pointer'} flex w-full gap-8 p-16 rounded-[16px]`} onClick={handleClick}>
      <div>
        <ImageCircle image={avatar}></ImageCircle>
      </div>
      <div className='flex-grow'>
        <div className='flex w-full items-center justify-between min-w-[200px]'>
          <h3 className='font-[500] text-primary dark:text-white text-[14px] leading-[21px]'>{name}</h3>
          <h5 className='text-[#888888] text-[10px] leading-[15px] content-center'>{time}</h5>
        </div>
        {/* <h4 className={`mt-6 line-clamp-2 leading-[18px] text-[12px] font-[500] ${active ? 'text-primary dark:text-white' : 'text-[#888888]'}`}>{displayedMessage}</h4> */}
      </div>
    </div>
  )
}
