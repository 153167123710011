import { createSvgIcon } from '@ui/icons/create-svg-icon'
import React from 'react'

// const ChatIcon = createSvgIcon(
//   <path d="M6.5 6.25H15.5M6.5 9.25H11M1.25 10.76C1.25 12.36 2.373 13.754 3.957 13.987C5.086 14.153 6.227 14.28 7.38 14.366C7.73 14.392 8.05 14.576 8.245 14.867L11 19L13.755 14.867C13.8516 14.7233 13.9798 14.6034 14.1297 14.5166C14.2795 14.4298 14.4472 14.3783 14.62 14.366C15.7652 14.2805 16.9069 14.1541 18.043 13.987C19.627 13.754 20.75 12.361 20.75 10.759V4.741C20.75 3.139 19.627 1.746 18.043 1.513C15.711 1.17072 13.357 0.99926 11 1C8.608 1 6.256 1.175 3.957 1.513C2.373 1.746 1.25 3.14 1.25 4.741V10.759V10.76Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//   );


// export default ChatIcon;
export default function ChatIcon() {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5 6.25H15.5M6.5 9.25H11M1.25 10.76C1.25 12.36 2.373 13.754 3.957 13.987C5.086 14.153 6.227 14.28 7.38 14.366C7.73 14.392 8.05 14.576 8.245 14.867L11 19L13.755 14.867C13.8516 14.7233 13.9798 14.6034 14.1297 14.5166C14.2795 14.4298 14.4472 14.3783 14.62 14.366C15.7652 14.2805 16.9069 14.1541 18.043 13.987C19.627 13.754 20.75 12.361 20.75 10.759V4.741C20.75 3.139 19.627 1.746 18.043 1.513C15.711 1.17072 13.357 0.99926 11 1C8.608 1 6.256 1.175 3.957 1.513C2.373 1.746 1.25 3.14 1.25 4.741V10.759V10.76Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}
